<template>
  <div>
    <div
      v-if="cargando && estadoResultados === 4"
      variant="success"
      class=" mt-5 d-flex justify-content-center"
    >
      <b-spinner variant="success" label="Loading..."></b-spinner>
    </div>
    <div
      v-if="estadoResultados === 4"
      v-show="
        $mq != 'mobile' &&
          $mq != 'tablet' &&
          $mq != 'laptop' &&
          $mq != 'mpequeño' &&
          visualizar_contenido === 1
      "
    >
      <b-card class="cuadro-rasgo-grande">
        <p class="texto-cuadro-grande">
          {{$t('mostpredisposed')}}
        </p>
        <b-row>
          <b-col class="d-flex justify-content-center">
            <b-card class="cuadro-rasgo-pequeño">
              <div id="svgcontainer1" class="svg-principal"></div>
            </b-card>
          </b-col>
          <b-col class="d-flex justify-content-center">
            <b-card class="cuadro-rasgo-pequeño">
              <div id="svgcontainer2" class="svg-principal"></div>
            </b-card>
          </b-col>
          <b-col class="d-flex justify-content-center">
            <b-card class="cuadro-rasgo-pequeño">
              <div id="svgcontainer3" class="svg-principal"></div>
            </b-card>
          </b-col>
          <b-col class="d-flex justify-content-center">
            <b-card class="cuadro-rasgo-pequeño">
              <div id="svgcontainer4" class="svg-principal"></div>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
      <b-card class="cuadro-rasgo-grande">
        <p class="texto-cuadro-grande">
          {{$t('lesspredisposed')}}
        </p>
        <b-row>
          <b-col class="d-flex justify-content-center">
            <b-card class="cuadro-rasgo-pequeño">
              <div id="svgcontainer5" class="svg-principal"></div>
            </b-card>
          </b-col>
          <b-col class="d-flex justify-content-center">
            <b-card class="cuadro-rasgo-pequeño">
              <div id="svgcontainer6" class="svg-principal"></div>
            </b-card>
          </b-col>
          <b-col class="d-flex justify-content-center">
            <b-card class="cuadro-rasgo-pequeño">
              <div id="svgcontainer7" class="svg-principal"></div>
            </b-card>
          </b-col>
          <b-col class="d-flex justify-content-center">
            <b-card class="cuadro-rasgo-pequeño">
              <div id="svgcontainer8" class="svg-principal"></div>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <div
      v-if="estadoResultados === 4"
      v-show="
        visualizar_contenido === 1 &&
          ($mq === 'mobile' ||
            $mq === 'tablet' ||
            $mq === 'laptop' ||
            $mq === 'mpequeño')
      "
    >
      <b-card class="cuadro-rasgo-grande">
        <p class="texto-cuadro-grande">
          {{$t('mostpredisposed')}}
        </p>
        <b-row>
          <b-col class="d-flex justify-content-center">
            <b-card class="cuadro-rasgo-pequeño">
              <div id="svgcontainer9" class="svg-principal"></div>
            </b-card>
          </b-col>
          <b-col class="d-flex justify-content-center">
            <b-card class="cuadro-rasgo-pequeño">
              <div id="svgcontainer10" class="svg-principal"></div>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="d-flex justify-content-center">
            <b-card class="cuadro-rasgo-pequeño">
              <div id="svgcontainer11" class="svg-principal"></div>
            </b-card>
          </b-col>
          <b-col class="d-flex justify-content-center">
            <b-card class="cuadro-rasgo-pequeño">
              <div id="svgcontainer12" class="svg-principal"></div>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
      <b-card class="cuadro-rasgo-grande">
        <p class="texto-cuadro-grande">
          {{$t('lesspredisposed')}}
        </p>
        <b-row>
          <b-col class="d-flex justify-content-center">
            <b-card class="cuadro-rasgo-pequeño">
              <div id="svgcontainer13" class="svg-principal"></div>
            </b-card>
          </b-col>
          <b-col class="d-flex justify-content-center">
            <b-card class="cuadro-rasgo-pequeño">
              <div id="svgcontainer14" class="svg-principal"></div>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="d-flex justify-content-center">
            <b-card class="cuadro-rasgo-pequeño">
              <div id="svgcontainer15" class="svg-principal"></div>
            </b-card>
          </b-col>
          <b-col class="d-flex justify-content-center">
            <b-card class="cuadro-rasgo-pequeño">
              <div id="svgcontainer16" class="svg-principal"></div>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <div
      v-if="estadoResultados === 0"
      class=" mt-5 d-flex justify-content-center"
    >
      <b-col>
        <b-row>
          <b-col>
            <b-row
              class="justify-content-center"
              style="margin:0px 0px 0px 0px;padding:0px 0px 0px 0px;"
            >
              <div class="codigoKit">
                <b-row>
                  <b-col class="imagenKit" cols="3">
                    <img
                      class="imagenKit"
                      :src="getImgLogoUrl('MB_icon saliva tube.png')"
                    />
                  </b-col>
                  <b-col>
                    <b-row class="filaCodigoKit">
                      <p class="codigoKitLetraP">{{$t('kit')}}</p>
                    </b-row>
                    <b-row class="filaCodigoKit">
                      <p class="codigoKitLetraG">{{ codigoKit }}</p>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
            </b-row>
            <b-row class="justify-content-center">
              <img
                :src="getImgLogoUrl('MB_icon trazabilidad 1.png')"
                style="width: 650px"
                class="imagenEstado"
              />
            </b-row>
          </b-col>
        </b-row>
        <b-row class="justify-content-center">
          <div class="cuadroTexto">
            <b-col>
              <b-row class="justify-content-center">
                <b-col class="imagenKitTexto justify-content-center" cols="4">
                  <img class="imagenKitTexto" :src="getImgLogoUrl('1.png')" />
                </b-col>
                <b-col class="textoEstado">
                  <p
                    style="font-size: 80%;font-weight:600; color: #747474;font-family: 'Montserrat', sans-serif;"
                  >
                     {{$t('estimatedtime4')}}
                  </p>
                  <p
                    style="font-size: 80%;font-weight:100; color: #747474;font-family: 'Montserrat', sans-serif;"
                  >
                  {{$t('registertext1')}}
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </div>
        </b-row>
      </b-col>
    </div>
    <div
      v-if="estadoResultados === 1"
      class=" mt-5 d-flex justify-content-center"
    >
      <b-col>
        <b-row>
          <b-col>
            <b-row
              class="justify-content-center"
              style="margin:0px 0px 0px 0px;padding:0px 0px 0px 0px;"
            >
              <div class="codigoKit">
                <b-row>
                  <b-col class="imagenKit" cols="3">
                    <img
                      class="imagenKit"
                      :src="getImgLogoUrl('MB_icon saliva tube.png')"
                    />
                  </b-col>
                  <b-col>
                    <b-row class="filaCodigoKit">
                      <p class="codigoKitLetraP"> {{$t('kit')}}</p>
                    </b-row>
                    <b-row class="filaCodigoKit">
                      <p class="codigoKitLetraG">{{ codigoKit }}</p>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
            </b-row>
            <b-row class="justify-content-center">
              <img
                :src="getImgLogoUrl('MB_icon trazabilidad 1.png')"
                style="width: 650px"
                class="imagenEstado"
              />
            </b-row>
          </b-col>
        </b-row>
        <b-row class="justify-content-center">
          <div class="cuadroTexto">
            <b-col>
              <b-row class="justify-content-center">
                <b-col class="imagenKitTexto justify-content-center" cols="4">
                  <img class="imagenKitTexto" :src="getImgLogoUrl('2.png')" />
                </b-col>
                <b-col class="textoEstado">
                  <p
                    style="font-size: 80%;font-weight:600; color: #747474;font-family: 'Montserrat', sans-serif;"
                  >
                  {{$t('notreceived')}}
                  </p>
                  <p
                    style="font-size: 80%;font-weight:100; color: #747474;font-family: 'Montserrat', sans-serif;"
                  >
                  {{$t('registertext2')}}
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </div>
        </b-row>
      </b-col>
    </div>
    <div
      v-if="estadoResultados === 2"
      class=" mt-5 d-flex justify-content-center"
    >
      <b-col>
        <b-row>
          <b-col>
            <b-row
              class="justify-content-center"
              style="margin:0px 0px 0px 0px;padding:0px 0px 0px 0px;"
            >
              <div class="codigoKit">
                <b-row>
                  <b-col class="imagenKit" cols="3">
                    <img
                      class="imagenKit"
                      :src="getImgLogoUrl('MB_icon saliva tube.png')"
                    />
                  </b-col>
                  <b-col>
                    <b-row class="filaCodigoKit">
                      <p class="codigoKitLetraP"> {{$t('kit')}}</p>
                    </b-row>
                    <b-row class="filaCodigoKit">
                      <p class="codigoKitLetraG">{{ codigoKit }}</p>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
            </b-row>
            <b-row class="justify-content-center">
              <img
                :src="getImgLogoUrl('MB_icon trazabilidad 2.png')"
                style="width: 650px"
                class="imagenEstado"
              />
            </b-row>
          </b-col>
        </b-row>
        <b-row class="justify-content-center">
          <div class="cuadroTexto">
            <b-col>
              <b-row class="justify-content-center">
                <b-col class="imagenKitTexto justify-content-center" cols="4">
                  <img class="imagenKitTexto" :src="getImgLogoUrl('3.png')" />
                </b-col>
                <b-col class="textoEstado">
                  <p
                    style="font-size: 80%;font-weight:600; color: #747474;font-family: 'Montserrat', sans-serif;"
                  >
                  {{$t('estimatedtime10')}}
                  </p>
                  <p
                    style="font-size: 80%;font-weight:100; color: #747474;font-family: 'Montserrat', sans-serif;"
                  >
                  {{$t('registertext3')}}
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </div>
        </b-row>
      </b-col>
    </div>
    <div
      v-if="estadoResultados === 3"
      class=" mt-5 d-flex justify-content-center"
    >
      <b-col>
        <b-row>
          <b-col>
            <b-row
              class="justify-content-center"
              style="margin:0px 0px 0px 0px;padding:0px 0px 0px 0px;"
            >
              <div class="codigoKit">
                <b-row>
                  <b-col class="imagenKit" cols="3">
                    <img
                      class="imagenKit"
                      :src="getImgLogoUrl('MB_icon saliva tube.png')"
                    />
                  </b-col>
                  <b-col>
                    <b-row class="filaCodigoKit">
                      <p class="codigoKitLetraP">{{$t('kit')}}</p>
                    </b-row>
                    <b-row class="filaCodigoKit">
                      <p class="codigoKitLetraG">{{ codigoKit }}</p>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
            </b-row>
            <b-row class="justify-content-center">
              <img
                :src="getImgLogoUrl('MB_icon trazabilidad 3.png')"
                style="width: 650px"
                class="imagenEstado"
              />
            </b-row>
          </b-col>
        </b-row>
        <b-row class="justify-content-center">
          <div class="cuadroTexto">
            <b-col>
              <b-row class="justify-content-center">
                <b-col class="imagenKitTexto justify-content-center" cols="4">
                  <img class="imagenKitTexto" :src="getImgLogoUrl('4.png')" />
                </b-col>
                <b-col class="textoEstado">
                  <p
                    style="font-size: 80%;font-weight:600; color: #747474;font-family: 'Montserrat', sans-serif;"
                  >
                  {{$t('estimatedtime20')}}
                  </p>
                  <p
                    style="font-size: 80%;font-weight:100; color: #747474;font-family: 'Montserrat', sans-serif;"
                  >
                  {{$t('registertext4')}}
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </div>
        </b-row>
      </b-col>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import * as d3 from 'd3';
export default {
  name: 'Inicio',

  data() {
    return {
      detalle: 0,
      cargando: false,
      codigoKit: '',
      visualizar_contenido: 0,
      valoresResultado: [],
      rasgosInforme: [],
      valoresPintarPositivos: [],
      valoresPintarNegativos: [],
      estadoResultados: -1,
      valoracion:[]
    };
  },
  created() {},
  methods: {
    getImgLogoUrl(url) {
      if (url != '') {
        return require('../images/resultados/' + url);
      }
    },
    getEstadoResultados() {
      let user = JSON.parse(localStorage.getItem('user'));
      axios
        .post(
          'https://api.mendelbrain.com:55020/api/mendels/resultado/estado',
          {
            token: user.accessToken
          },
          {
            headers: {
              Authorization: 'Bearer ' + user.accessToken
            }
          }
        )
        .then(
          response => {
            this.estadoResultados = response.data.estado;
            this.codigoKit = response.data.codigoKit;
            this.$parent.estadoRes = response.data.estado;
            if (this.estadoResultados == 4) {
              this.obtenerValoracion(this.$parent.language);
            }
          },
          error => {
                if (error.response.status === 401) {
                  this.$store.dispatch('auth/logout');
                  this.$router.push('/login');
                }
          }
        );
  },

    obtenerValoracion(language) {
      this.cargando = true;
      this.busy = true;
      let user = JSON.parse(localStorage.getItem('user'));
      axios
        .post(
          'https://api.mendelbrain.com:55020/api/mendels/resultado',
          {
            token: user.accessToken,
            language: language
          },
          {
            headers: {
              Authorization: 'Bearer ' + user.accessToken
            }
          }
        )
        .then(
          response => {
            this.codigoKit = response.data.codigoKit;
            this.valoracion = response.data.valoracion;
            this.valoresResultado = response.data.valoresResultado;
            this.obtenerInforme();
            this.busy = false;
          },
          error => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            this.busy = false;
          }
        );
    },
    obtenerInforme() {
      this.busy = true;
      let user = JSON.parse(localStorage.getItem('user'));
      axios
        .post(
          'https://api.mendelbrain.com:55020/api/mendels/informe',
          {
            valoracion: this.valoracion
          },
          {
            headers: {
              Authorization: 'Bearer ' + user.accessToken
            }
          }
        )
        .then(
          response => {
            this.rasgosInforme = response.data;
            this.busy = false;
            this.ordenarValores();
          },
          error => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            this.busy = false;
          }
        );
    },
    ordenarValores() {
      this.valoresResultado = this.valoresResultado.filter(
        data => data.score != 'NaN'
      );

      var valoresPintarAux = [];

      for (let i = 0; i < this.valoresResultado.length; i++) {
        for (let j = 0; j < this.rasgosInforme.gruposResultado.length; j++) {
          for (
            let k = 0;
            k < this.rasgosInforme.gruposResultado[j].rasgosInforme.length;
            k++
          ) {
            if (
              this.rasgosInforme.gruposResultado[j].rasgosInforme[k].id_trait ==
                this.valoresResultado[i].id_trait_master &&
              this.rasgosInforme.gruposResultado[j].nombre != 'NO_GROUP'
            ) {
              valoresPintarAux.push(this.valoresResultado[i]);
            }
          }
        }
      }
      valoresPintarAux.sort((a, b) => {
        return b.score - a.score;
      });

      for (let i = 0; i < 4 && i < valoresPintarAux.length; i++) {
        this.valoresPintarPositivos.push(valoresPintarAux[i]);
      }
      valoresPintarAux.sort((a, b) => {
        return a.score - b.score;
      });

      for (let i = 0; i < 4 && i < valoresPintarAux.length; i++) {
        this.valoresPintarNegativos.push(valoresPintarAux[i]);
      }

      this.cargando = false;
      this.Visualizar();
    },

    // GRÁFICOS
    InitSVG(ancho, contenedor, rasgoInforme, valor) {
      d3.select('#' + contenedor)
        .select('svg')
        .remove();
      var svg = d3
        .select('#' + contenedor)
        .append('svg')
        .attr('width', ancho)
        .attr('height', ancho)
        .style('display', 'block')
        .style('margin', '0px 0px 0px 0px');

      var defs = svg.append('defs');

      var dropShadowFilter = defs
        .append('svg:filter')
        .attr('id', 'drop-shadow')
        .attr('filterUnits', 'userSpaceOnUse')
        .attr('width', '130%')
        .attr('height', '130%');
      dropShadowFilter
        .append('svg:feGaussianBlur')
        .attr('in', 'SourceGraphic')
        .attr('stdDeviation', 5)
        .attr('result', 'blur-out');
      dropShadowFilter
        .append('svg:feColorMatrix')
        .attr('in', 'blur-out')
        .attr('type', 'hueRotate')
        .attr('values', 180)
        .attr('result', 'color-out');
      dropShadowFilter
        .append('svg:feOffset')
        .attr('in', 'color-out')
        .attr('dx', 3)
        .attr('dy', 3)
        .attr('result', 'the-shadow');
      dropShadowFilter
        .append('svg:feBlend')
        .attr('in', 'SourceGraphic')
        .attr('in2', 'the-shadow')
        .attr('mode', 'normal');

      //Imagen del rasgo
      svg
        .append('image')
        .attr('x', ancho / 4)
        .attr('y', ancho / 10)
        .attr('width', ancho / 2)
        .attr('height', ancho / 2 - 5)
        .attr('xlink:href', this.ObtenerImagen(rasgoInforme.logo));
      // Base
      svg
        .append('rect')
        .attr('y', (ancho * 3) / 5)
        .attr('x', ancho / 5)
        .attr('width', (ancho * 3) / 5)
        .attr('height', ancho / 10)
        .attr('rx', 20)
        .style('fill', '#eceaea')
        .style('filter', 'url(#drop-shadow)')
        .style('stroke-width', 14);

      var barra_x1 = ancho / 2;
      var barra_ancho = (((3 * ancho) / 10) * this.getScore(valor.score)) / 100;

      var round_top_left = 0;
      var round_top_right = 0;
      var round_bottom_left = 0;
      var round_bottom_right = 0;
      var color = '';

      if (valor.score > 0) {
        round_top_left = 0;
        round_top_right = 1;
        round_bottom_left = 0;
        round_bottom_right = 1;
        color = '#8caf36';
      } else if (valor.score < 0) {
        barra_x1 =
          ancho / 2 - (((3 * ancho) / 10) * this.getScore(valor.score)) / 100;
        round_top_left = 1;
        round_top_right = 0;
        round_bottom_left = 1;
        round_bottom_right = 0;
        color = '#c6cc33';
      }
      /*x: x-coordinate
      y: y-coordinate
      w: width
      h: height
      r: corner radius
      tl: top_left rounded?
      tr: top_right rounded?
      bl: bottom_left rounded?
      br: bottom_right rounded?*/
      svg
        .append('path')
        .attr(
          'd',
          this.rounded_rect(
            barra_x1,
            (ancho * 3) / 5,
            barra_ancho,
            ancho / 10,
            ancho / 20,
            round_top_left,
            round_top_right,
            round_bottom_left,
            round_bottom_right
          )
        )
        .style('fill', function() {
          return color;
        });

      //Linea central
      svg
        .append('line')
        .attr('x1', ancho / 2)
        .attr('y1', (ancho * 3) / 5 - 3)
        .attr('x2', ancho / 2)
        .attr('y2', (ancho * 3) / 5 + ancho / 10 + 3)
        .attr('stroke', 'black')
        .style('stroke-width', 2);

      //Texto pie

      svg
        .append('text')
        .text(rasgoInforme.cabecera)
        .attr('x', ancho / 2)
        .attr('y', (ancho * 3) / 5 + ancho / 10 + 30)
        .attr('fill', 'gray')
        .attr('dy', '0.35em')
        .attr('font-size', '13')
        .attr('font-weight', 550)
        .attr('font-family', 'Montserrat, sans-serif')
        .attr('text-anchor', 'middle');

      // svg
      //   .append('text')
      //  .text(rasgoInforme.cabecera2)
      // .attr('x', ancho / 2)
      // .attr('y', (ancho * 3) / 5 + ancho / 10 + 60)
      // .attr('fill', 'gray')
      // .attr('dy', '0.35em')
      // .attr('width', '15px')
      // .attr('font-size', '13')
      // .attr('font-weight', 350)
      //.attr('font-family', 'Montserrat, sans-serif')
      //.attr('text-anchor', 'middle');
    },
    InitSVG2(ancho, contenedor, rasgoInforme, valor) {
      d3.select('#' + contenedor)
        .select('svg')
        .remove();
      var svg2 = d3
        .select('#' + contenedor)
        .append('svg')
        .attr('width', ancho)
        .attr('height', ancho)
        .style('display', 'block')
        .style('margin', '0px 0px 0px 0px');

      var defs2 = svg2.append('defs');

      var dropShadowFilter2 = defs2
        .append('svg:filter')
        .attr('id', 'drop-shadow2')
        .attr('filterUnits', 'userSpaceOnUse')
        .attr('width', '130%')
        .attr('height', '130%');
      dropShadowFilter2
        .append('svg:feGaussianBlur')
        .attr('in', 'SourceGraphic')
        .attr('stdDeviation', 5)
        .attr('result', 'blur-out');
      dropShadowFilter2
        .append('svg:feColorMatrix')
        .attr('in', 'blur-out')
        .attr('type', 'hueRotate')
        .attr('values', 180)
        .attr('result', 'color-out');
      dropShadowFilter2
        .append('svg:feOffset')
        .attr('in', 'color-out')
        .attr('dx', 3)
        .attr('dy', 3)
        .attr('result', 'the-shadow');
      dropShadowFilter2
        .append('svg:feBlend')
        .attr('in', 'SourceGraphic')
        .attr('in2', 'the-shadow')
        .attr('mode', 'normal');

      //Imagen del rasgo
      svg2
        .append('image')
        .attr('x', ancho / 4)
        .attr('y', ancho / 10)
        .attr('width', ancho / 2)
        .attr('height', ancho / 2 - 5)
        .attr('xlink:href', this.ObtenerImagen(rasgoInforme.logo));
      // Base
      svg2
        .append('rect')
        .attr('y', (ancho * 3) / 5)
        .attr('x', ancho / 5)
        .attr('width', (ancho * 3) / 5)
        .attr('height', ancho / 10)
        .attr('rx', 20)
        .style('fill', '#eceaea')
        .style('filter', 'url(#drop-shadow2)')
        .style('stroke-width', 14);

      var barra_x1 = ancho / 2;
      var barra_ancho = (((3 * ancho) / 10) * this.getScore(valor.score)) / 100;

      var round_top_left = 0;
      var round_top_right = 0;
      var round_bottom_left = 0;
      var round_bottom_right = 0;
      var color = '';

      if (valor.score > 0) {
        round_top_left = 0;
        round_top_right = 1;
        round_bottom_left = 0;
        round_bottom_right = 1;
        color = '#8caf36';
      } else if (valor.score < 0) {
        barra_x1 =
          ancho / 2 - (((3 * ancho) / 10) * this.getScore(valor.score)) / 100;
        round_top_left = 1;
        round_top_right = 0;
        round_bottom_left = 1;
        round_bottom_right = 0;
        color = '#c6cc33';
      }
      /*x: x-coordinate
      y: y-coordinate
      w: width
      h: height
      r: corner radius
      tl: top_left rounded?
      tr: top_right rounded?
      bl: bottom_left rounded?
      br: bottom_right rounded?*/
      svg2
        .append('path')
        .attr(
          'd',
          this.rounded_rect(
            barra_x1,
            (ancho * 3) / 5,
            barra_ancho,
            ancho / 10,
            ancho / 20,
            round_top_left,
            round_top_right,
            round_bottom_left,
            round_bottom_right
          )
        )
        .style('fill', function() {
          return color;
        });

      //Linea central
      svg2
        .append('line')
        .attr('x1', ancho / 2)
        .attr('y1', (ancho * 3) / 5 - 3)
        .attr('x2', ancho / 2)
        .attr('y2', (ancho * 3) / 5 + ancho / 10 + 3)
        .attr('stroke', 'black')
        .style('stroke-width', 2);

      //Texto pie

      svg2
        .append('text')
        .text(rasgoInforme.cabecera)
        .attr('x', ancho / 2)
        .attr('y', (ancho * 3) / 5 + ancho / 10 + 30)
        .attr('fill', 'gray')
        .attr('dy', '0.35em')
        .attr('font-size', '13')
        .attr('font-weight', 550)
        .attr('font-family', 'Montserrat, sans-serif')
        .attr('text-anchor', 'middle');

      // svg
      //   .append('text')
      //  .text(rasgoInforme.cabecera2)
      // .attr('x', ancho / 2)
      // .attr('y', (ancho * 3) / 5 + ancho / 10 + 60)
      // .attr('fill', 'gray')
      // .attr('dy', '0.35em')
      // .attr('width', '15px')
      // .attr('font-size', '13')
      // .attr('font-weight', 350)
      //.attr('font-family', 'Montserrat, sans-serif')
      //.attr('text-anchor', 'middle');
    },
    ObtenerImagen(imagen) {
      return require('../images/resultados/' + imagen);
    },
    ObtenerRasgoInforme(valorRasgo) {
      //console.logvalorRasgo);
      for (let i = 0; i < this.rasgosInforme.gruposResultado.length; i++) {
        for (
          let j = 0;
          j < this.rasgosInforme.gruposResultado[i].rasgosInforme.length;
          j++
        ) {
          if (
            this.rasgosInforme.gruposResultado[i].rasgosInforme[j].id_trait ==
            valorRasgo.id_trait_master
          ) {
            return this.rasgosInforme.gruposResultado[i].rasgosInforme[j];
          }
        }
      }
    },
    getScore(score) {
      if (score < 0) {
        score = score * -1;
      }
      // Valor mínimo para pintar algo
      if (score < 0.3) {
        return 10;
      }
      if (score <= 3) {
        return (score * 100) / 3;
      } else if (score > 3) {
        return 100;
      } else if (score == 0) {
        return 0;
      }
    },
    Visualizar() {
      d3.select('svg').remove();

      var to_positivos = 4;
      if (this.valoresPintarPositivos.length < 4) {
        to_positivos = this.valoresPintarPositivos.length;
      }
      for (let i = 0; i < to_positivos; i++) {
        var contenedor = i + 1;
        this.InitSVG(
          250,
          'svgcontainer' + contenedor,
          this.ObtenerRasgoInforme(this.valoresPintarPositivos[i]),
          this.valoresPintarPositivos[i]
        );
        contenedor = contenedor + 8;
        this.InitSVG2(
          250,
          'svgcontainer' + contenedor,
          this.ObtenerRasgoInforme(this.valoresPintarPositivos[i]),
          this.valoresPintarPositivos[i]
        );
      }
      var to_negativos = 4;
      if (this.valoresPintarNegativos.length < 4) {
        to_negativos = this.valoresPintarNegativos.length;
      }
      for (let i = 0; i < to_negativos; i++) {
        contenedor = 4 + i + 1;
        this.InitSVG(
          250,
          'svgcontainer' + contenedor,
          this.ObtenerRasgoInforme(this.valoresPintarNegativos[i]),
          this.valoresPintarNegativos[i]
        );
        contenedor = contenedor + 8;
        this.InitSVG2(
          250,
          'svgcontainer' + contenedor,
          this.ObtenerRasgoInforme(this.valoresPintarNegativos[i]),
          this.valoresPintarNegativos[i]
        );
      }
      this.visualizar_contenido = 1;
    },

    /*x: x-coordinate
      y: y-coordinate
      w: width
      h: height
      r: corner radius
      tl: top_left rounded?
      tr: top_right rounded?
      bl: bottom_left rounded?
      br: bottom_right rounded?*/
    rounded_rect(x, y, w, h, r, tl, tr, bl, br) {
      var retval;
      retval = 'M' + (x + r) + ',' + y;
      retval += 'h' + (w - 2 * r);
      if (tr) {
        retval += 'a' + r + ',' + r + ' 0 0 1 ' + r + ',' + r;
      } else {
        retval += 'h' + r;
        retval += 'v' + r;
      }
      retval += 'v' + (h - 2 * r);
      if (br) {
        retval += 'a' + r + ',' + r + ' 0 0 1 ' + -r + ',' + r;
      } else {
        retval += 'v' + r;
        retval += 'h' + -r;
      }
      retval += 'h' + (2 * r - w);
      if (bl) {
        retval += 'a' + r + ',' + r + ' 0 0 1 ' + -r + ',' + -r;
      } else {
        retval += 'h' + -r;
        retval += 'v' + -r;
      }
      retval += 'v' + (2 * r - h);
      if (tl) {
        retval += 'a' + r + ',' + r + ' 0 0 1 ' + r + ',' + -r;
      } else {
        retval += 'v' + -r;
        retval += 'h' + r;
      }
      retval += 'z';
      return retval;
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    this.usuario = JSON.parse(localStorage.getItem('user'));
    this.getEstadoResultados();
    //this.obtenerValoracion('ESP');
  },
  components: {}
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

.textoEstado {
  padding: 30px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}
.filaCodigoKit {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  height: 20px;
}
.imagenEstado {
  width: 650px;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}
.cuadroTexto {
  width: 650px;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}
.imagenKit {
  padding: 0px 0px 0px 0px;
  margin: 5px 0px 0px 0px;
  text-align: right;
  width: 30px;
  height: 35px;
}
.imagenKitTexto {
  padding: 0px 0px 0px 0px;
  margin: 10px 0px 0px 0px;
  text-align: center;
  width: 200px;
  height: 200px;
}
.codigoKit {
  border-radius: 50px 50px 50px 50px;
  border-style: solid;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 30px 0px;
  color: #252424;
  border-color: #efefef;
  background: linear-gradient(0.95turn, #399a29, #8caf36);
  text-decoration: none;
  width: 300px;
  height: 60px;
}
.codigoKitLetraG {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  color: #ffffff;
  text-align: left;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}
.codigoKitLetraP {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  color: #ffffff;
  text-align: left;
  padding: 0px 0px 0px 0px;
  margin: 5px 0px 0px 0px;
}
.cuadro-rasgo-grande {
  text-align: center;
  border-width: 5px;
  border-color: rgb(236, 234, 234);
  background-color: rgb(236, 234, 234);
  margin: 10px 10px 10px 10px;
  border-radius: 20px 20px 20px 20px;
  padding: 10px 10px 10px 10px;
}

.cuadro-rasgo-pequeño {
  text-align: center;
  border-width: 5px;
  border-color: white;
  margin: 0px 0px 0px 0px;
  border-radius: 20px 20px 20px 20px;
  width: 300px;
  padding: 0px 0px 0px 0px;
  box-shadow: 0px 0px 55px 0px rgb(209, 206, 206);
}
.texto-cuadro-grande {
  color: #757575;
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  font-weight: 400;
  text-align: left;
  margin: 0px 10px 30px 10px;
  padding: 0px 10px 0px 10px;
}
@media all and (min-width: 320px) and (max-width: 1410px) {
  .cuadro-rasgo-grande {
    text-align: center;
    border-width: 5px;
    border-color: rgb(236, 234, 234);
    background-color: rgb(236, 234, 234);
    margin: 20px 20px 20px 20px;
    border-radius: 20px 20px 20px 20px;
    padding: 20px 20px 20px 20px;
  }
  .cuadro-rasgo-pequeño {
    text-align: center;
    border-width: 5px;
    border-color: white;
    margin: 0px -20px 20px -20px;
    border-radius: 20px 20px 20px 20px;
    width: 300px;
    padding: 0px 0px 0px 0px;
    box-shadow: 0px 0px 55px 0px rgb(209, 206, 206);
  }
  .texto-cuadro-grande {
    color: #757575;
    font-family: 'Montserrat', sans-serif;
    font-size: 17px;
    font-weight: 400;
    text-align: center;
    margin: 0px 10px 30px 10px;
    padding: 0px 10px 0px 10px;
  }
  @media all and (min-width: 451px) and (max-width: 700px) {
    .textoEstado {
      padding: 40px 0px 0px 10px;
      margin: 0px 0px 0px 0px;
      font-size: 15px;
    }
    .imagenEstado {
      width: 400px;
      padding: 0px 0px 0px 0px;
      margin: 0px 0px 0px 0px;
    }
    .cuadroTexto {
      width: 400px;
      padding: 0px 0px 0px 0px;
      margin: 0px 0px 0px 0px;
    }
    .imagenKitTexto {
      padding: 0px 0px 0px 0px;
      margin: 20px 0px 0px 0px;
      text-align: right;
      width: 130px;
      height: 130px;
    }
    .codigoKit {
      border-radius: 50px 50px 50px 50px;
      border-style: solid;
      padding: 0px 0px 0px 0px;
      margin: 0px 0px 30px 0px;
      color: #252424;
      border-color: #efefef;
      background: linear-gradient(0.95turn, #399a29, #8caf36);
      text-decoration: none;
      width: 250px;
      height: 60px;
    }
  }
  @media all and (max-width: 450px) {
    .textoEstado {
      padding: 40px 10px 0px 15px;
      margin: 0px 0px 0px 0px;
      font-size: 13px;
    }
    .filaCodigoKit {
      padding: 0px 0px 0px 0px;
      margin: 0px 0px 0px 0px;
      height: 20px;
    }
    .imagenEstado {
      width: 350px;
      padding: 0px 0px 0px 0px;
      margin: 0px 0px 0px 0px;
    }
    .cuadroTexto {
      width: 350px;
      padding: 0px 0px 0px 0px;
      margin: 0px 0px 0px 0px;
    }
    .imagenKit {
      padding: 0px 0px 0px 0px;
      margin: 5px 0px 0px 0px;
      text-align: right;
      width: 30px;
      height: 35px;
    }
    .imagenKitTexto {
      padding: 0px 0px 0px 0px;
      margin: 20px 0px 0px 0px;
      text-align: right;
      width: 120px;
      height: 120px;
    }
    .codigoKit {
      border-radius: 50px 50px 50px 50px;
      border-style: solid;
      padding: 0px 0px 0px 0px;
      margin: 0px 0px 30px 0px;
      color: #252424;
      border-color: #efefef;
      background: linear-gradient(0.95turn, #399a29, #8caf36);
      text-decoration: none;
      width: 250px;
      height: 60px;
    }
    .codigoKitLetraG {
      font-family: 'Montserrat', sans-serif;
      font-size: 20px;
      color: #ffffff;
      text-align: left;
      padding: 0px 0px 0px 0px;
      margin: 0px 0px 0px 0px;
    }
    .codigoKitLetraP {
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
      color: #ffffff;
      text-align: left;
      padding: 0px 0px 0px 0px;
      margin: 5px 0px 0px 0px;
    }
  }
}
</style>
